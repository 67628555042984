<script>
import MenuFieldForm from "@/views/menu/menu/menu-field/menuFieldForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    menuService: "menuService"
  }
})
export default class MenuFieldDetail extends mixins(MenuFieldForm) {
  formId = "detail-menu-field-form";

  get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.menuFields_detail;
  }

  async afterCreate () {
    this.model = await this.menuService.readField(this.$route.params.idParent, this.$route.params.id);
    this.isReady = true;
  }
}
</script>
